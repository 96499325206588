import { useEffect } from 'react';

import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

import { ILocationsMarqueeBlock } from '@/interfaces/pages';
import { Container, Section } from '@/styles/global';

import { Locations, LocationsRow, Location, Label, Text } from './styles';
import ScrollReveal from '../ScrollReveal';

interface ILocationsMarquee {
    content: ILocationsMarqueeBlock;
}

const MARQUEE_SPEED = 40;

const LocationsMarquee = ({ content }: ILocationsMarquee) => {
    const { heading, subHeading, locations, background, headingPosition } = content;

    const { ref, inView } = useInView({
        threshold: 0.6,
    });

    useEffect(() => {
        if (inView) {
            document.body.className = background;
        }
    }, [inView, background]);

    return (
        <Section ref={ref}>
            {heading && headingPosition === 'top' && (
                <Container size="medium">
                    <ScrollReveal>
                        <Text>
                            <h2>{heading}</h2>
                            <p>{subHeading}</p>
                        </Text>
                    </ScrollReveal>
                </Container>
            )}
            <Locations gradient={false} speed={MARQUEE_SPEED} pauseOnHover={false}>
                <LocationsRow>
                    {locations.map((location) => {
                        const { id, city, image } = location;
                        return (
                            <Location key={id} className={`${id % 2 == 0 ? 'odd' : ''}`}>
                                <Image src={image.path} alt={image.alt} width={384} height={384} unoptimized />
                                <Label>{city}</Label>
                            </Location>
                        );
                    })}
                </LocationsRow>
            </Locations>
            {heading && headingPosition === 'bottom' && (
                <Container size="medium">
                    <ScrollReveal>
                        <Text>
                            <h2>{heading}</h2>
                            <p>{subHeading}</p>
                        </Text>
                    </ScrollReveal>
                </Container>
            )}
        </Section>
    );
};

export default LocationsMarquee;

import Marquee from 'react-fast-marquee';
import styled from 'styled-components';

export const Locations = styled(Marquee)`
    box-sizing: border-box;
    margin-bottom: 3rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        margin-bottom: 5.7rem;
    }
`;

export const LocationsRow = styled.div`
    display: flex;
`;

export const Location = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 13.2rem;
    margin-left: -2rem;

    img {
        border-radius: 50%;
    }

    &.odd {
        flex-direction: column-reverse;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 24rem;
        margin-left: -5rem;
    }
`;

export const Label = styled.span`
    background-color: ${({ theme }) => theme.colours.surface.deepBlue};
    color: white;
    width: 5.8rem;
    height: 5.8rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: -2rem;
    text-align: center;
    padding: 0 10px;

    ${Location}.odd & {
        margin-top: 0;
        transform: translateY(2rem);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 7.7rem;
        height: 7.7rem;
        margin-top: -2rem;
        font-size: 1.1rem;
        padding: 0 20px;
    }
`;

export const Text = styled.div`
    text-align: center;
    &.fadeInUp {
        transition-delay: 1.1s;
    }

    p {
        font-size: 1.313rem;
        line-height: 2.188rem;
        letter-spacing: 0.022rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        p {
            font-size: 1.313rem;
            line-height: 2.188rem;
            letter-spacing: 0.022rem;
        }
    }
`;
